/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 25, 2022 */



@font-face {
    font-family: 'reenie_beanieregular';
    src: url('reenie-beanie.regular-webfont.woff2') format('woff2'),
         url('reenie-beanie.regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}